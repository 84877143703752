<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="nav-panel anan-tabs anan-tabs-line anan-tabs-normal anan-tabs-top">
          <div class="anan-tabs__nav">
            <div
              class="anan-tabs__nav-warp px-2"
              style="margin-top: 5px"
            >
              <div
                class="anan-tabs__nav-tabs"
                style="transform: translateX(0px)"
              >
                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'all' }"
                  style="white-space: normal"
                  @click="GetData((status = 0)), (isActive = 'all')"
                >
                  <span> {{ $t("all") }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'not print' }"
                  style="white-space: normal"
                  @click="GetData((status = 1)), (isActive = 'not print')"
                >
                  <span> {{ $t("pendingReview") }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'printed' }"
                  style="white-space: normal"
                  @click="GetData((status = 2)), (isActive = 'printed')"
                >
                  <span> {{ $t("approve") }} </span>
                </div>

                <div
                  class="anan-tabs__nav-tab"
                  :class="{ active: isActive === 'notprinted' }"
                  style="white-space: normal"
                  @click="GetData((status = 3)), (isActive = 'notprinted')"
                >
                  <span> {{ $t("key-35") }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
          <h3 class="font-weight-bolder">
            {{ $t('key-247') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search_text"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @input="GetData(status)"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <b-form-select
              v-model="selected"
              :options="options"
              class="w-50 ml-1"
              size="sm"
              @change="GetData(status = selected)"
            />
          </div>
        </div>
        <b-overlay
          :show="showOver"
          variant="transparent"
          opacity="0.99"
          blur="5px"
          rounded="sm"
        >
          <template #overlay>
            <div class="text-center">
              <p id="cancel-label">
                กรุณารอสักครู่...
              </p>
            </div>
          </template>
          <div class="pl-2 pr-2 pt-1">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              show-empty
              class="text-center"
            >
              <template #empty>
                <div class="text-center">
                  <img
                    src="@/assets/images/anan-img/empty/empty.png"
                    alt="empty"
                    width="180px"
                  >
                  <p>No Data</p>
                </div>
              </template>
              <template #cell(file_bank)="data">
                <img
                  :src="GetImg('FileBank',data.item.file_bank)"
                  alt="Preview"
                  width="50"
                  class="text-center"
                  @click="showPicture(data.item.file_bank)"
                >
                <!-- :src="GetImg('FileBank',data.item.file_bank)" -->
                <vue-image-lightbox-carousel
                  ref="lightbox"
                  :show="showLightbox"
                  :images="images"
                  :show-caption="false"
                  @close="closeBox()"
                />
                <!-- <img
                    :src="`http://127.0.0.1:4444/api/Bank/getimg/${data.item.file_bank}`"
                    alt="Preview"
                    width="50"
                    class="text-center"
                    @click="showPicture(`http://127.0.0.1:4444/api/Bank/getimg/${data.item.file_bank}`)"
                  > -->
              </template>
              <template #cell(index)="data">
                {{ data.item.itemnumber_text }}
              </template>
              <template #cell(username)="data">
                <b-link
                  v-if="data.item.username"
                  :to="{ name: 'admin-employee-edit', params: { id: data.item.uid } }"
                >
                  {{ data.item.username }}
                </b-link>
              </template>
              <template #cell(status)="data">

                <b-badge
                  v-if="data.item.status === 2"
                  pill
                  variant="success"
                >
                  {{ $t('approve') }}
                </b-badge>
                <b-badge
                  v-if="data.item.status === 3"
                  pill
                  variant="danger"
                >
                  {{ $t('key-35') }}
                </b-badge>
                <b-badge
                  v-if="data.item.status === 1"
                  pill
                  variant="warning"
                >
                  {{ $t('pendingReview') }}
                </b-badge>
              </template>
              <template #cell(actions)="data">
                <div class="text-center">
                  <b-button
                    v-if="data.item.status === 1"
                    size="sm"
                    class="btn-gradient-success ml-1 "
                    @click="Approved(data.item)"
                  >
                    <feather-icon
                      icon="CheckIcon"
                      class="mr-10"
                    />
                    {{ $t("approve") }}
                  </b-button>
                  <b-button
                    v-if="data.item.status === 1"
                    size="sm"
                    class="btn-gradient-danger ml-1 "
                    @click="notApproved(data.item)"
                  >
                    <feather-icon
                      icon="XIcon"
                      class="mr-10"
                    />
                    {{ $t("key-35") }}
                  </b-button>
                </div>
              </template>
            </b-table>

            <div class="demo-spacing-0 d-flex justify-content-end mb-2">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @input="GetData(status)"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable,
  //   BLink,
  BFormSelect,
  //   BModal,
  //   BFormGroup,
  //   BFormInput,
  //   BFormTextarea,
  BPagination,
  BOverlay,
  BButton,
  BBadge,
  BLink,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
// import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    // BModal,
    // BFormGroup,
    // BFormInput,
    // BFormTextarea,
    // ValidationProvider,
    ValidationObserver,
    BPagination,
    BOverlay,
    BButton,
    BBadge,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      isActive: 'all',
      required,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      orderId_text: '',
      showOver: false,
      selected: 0,

      search_text: null,

      items: [
        // {
        //   id: '1',
        //   nameAccount: 'มานะ นามสมมุติ',
        //   nameBank: 'กสิกร',
        //   numberBank: '092-9823-248',
        //   img: 'https://static.trueplookpanya.com/tppy/member/m_545000_547500/545920/cms/images/%E0%B9%80%E0%B8%9B%E0%B8%B4%E0%B8%94%E0%B8%82%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B9%E0%B8%A5%E0%B8%A5%E0%B9%88%E0%B8%B2%E0%B8%AA%E0%B8%B8%E0%B8%94%20%E0%B8%9A%E0%B8%B1%E0%B8%95%E0%B8%A3%E0%B8%9B%E0%B8%A3%E0%B8%B0%E0%B8%8A%E0%B8%B2%E0%B8%8A%E0%B8%99%E0%B8%AB%E0%B8%B2%E0%B8%A2%20%E0%B8%95%E0%B9%89%E0%B8%AD%E0%B8%87%E0%B8%97%E0%B8%B3%E0%B8%AD%E0%B8%A2%E0%B9%88%E0%B8%B2%E0%B8%87%E0%B9%84%E0%B8%A3%E0%B8%9A%E0%B9%89%E0%B8%B2%E0%B8%872.png',
        //   status: 1,
        // },

      ],
      status: 0,
    }
  },
  computed: {
    options() {
      return [
        { value: 0, text: this.$t('all') },
        { value: 1, text: this.$t('pendingReview') },
        { value: 2, text: this.$t('approve') },
        { value: 3, text: this.$t('key-35') },
      ]
    },
    fields() {
      return [
        { key: 'index', label: this.$t('sequence') },
        { key: 'name_account', label: this.$t('accountName') },
        { key: 'username', label: this.$t('customer') },
        { key: 'name_bank', label: this.$t('key-55') },
        { key: 'number_account', label: this.$t('key-48') },
        { key: 'file_bank', label: this.$t('key-248') },
        { key: 'status', label: this.$t('status') },
        {
          key: 'actions',
          label: this.$t('manage'),
          thStyle: { width: '300px' },
        },
      ]
    },
  },
  watch: {
    '$route.query.querykey': {
      immediate: true,
      handler() {
        this.handleQueryChange()
      },
    },
  },
  mounted() {
    // this.GetData(this.status)
    this.handleQueryChange()
  },
  methods: {
    handleQueryChange() {
      const { querykey } = this.$route.query
      if (querykey) {
        if (Number(querykey) === 1) {
          this.isActive = 'not print'
          this.GetData(this.status = 1)
        }
      } else {
        this.GetData(this.status)
      }
    },
    async GetData() {
      this.showOver = true
      try {
        const params = {
          status: this.status,
          perPage: this.perPage,
          page: this.currentPage,
          search_text: this.search_text,
        }
        const { data: res } = await this.$http.get('/Refill/getBankcus/Verify', { params })
        this.items = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.SwalError(err)
        this.showOver = false
      }
    },
    Approved(data) {
      this.$bvModal
        .msgBoxConfirm('คุณต้องการอนุมัติรายการนี้หรือไม่?', {
          title: 'ยืนยันการอนุมัติ',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            const obj = {
              id: data._id,
              status: 2,
            }
            this.$http.post('/Refill/StoreBank', obj)
              .then(() => {
                this.GetData()
                this.Success('ทำรายการอนุมัติเรียบร้อยแล้ว')
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    notApproved(data) {
      this.$bvModal
        .msgBoxConfirm(this.$t('key-253'), {
          title: this.$t('key-254'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            const obj = {
              id: data._id,
              status: 3,
            }
            this.$http.post('/Refill/StoreBank', obj)
              .then(() => {
                this.GetData()
                this.Success('ทำรายการไม่อนุมัติเรียบร้อยแล้ว')
              })
              .catch(err => {
                console.log(err)
                this.SwalError(err)
              })
          }
        })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/)
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('FileBank', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },

    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style></style>
